import Modal from "react-bootstrap/Modal";
import React from "react";
import htmlParser from "html-react-parser";

const ModalWrapper = (props) => {
  return (
    <div className="modal-wrapper">
      <Modal show={props.show} onHide={props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{htmlParser(props.content)}</Modal.Body>
      </Modal>
    </div>
  );
};

export default ModalWrapper;
