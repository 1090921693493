import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class Footer extends Component {
  render() {
    const year = new Date().getFullYear();

    return (
      <div className="footer">
        <footer>
          <Container>
            <Row className="">
              <Col xs="12" sm="4" md="3" lg="2" xl="3" className="line">
                &copy; {year} Szkolenia Ultrasonograficzne E&M Litwińskie
              </Col>
              <Col xs="6" sm="4" md="3" lg="2" className="line">
                <a href="/polityka-prywatnosci">Polityka prywatności</a>
              </Col>
              <Col xs="6" sm="4" md="3" lg="2" className="line">
                <a href="/poprzednie-edycje">Poprzednie edycje</a>
              </Col>
              <Col xs="6" sm="4" md="3" lg="2" className="line">
                <div className="">
                  <a href="/docs/Regulamin_uczestnicwa.pdf">
                    Regulamin uczestnictwa
                  </a>
                </div>
                <div className="">
                  <a href="/docs/Regulamin_swiadczenia_uslug_droga_elektroniczna.pdf">
                    Regulamin rejestracji online
                  </a>
                </div>
              </Col>

              <Col xs="6" sm="auto" className="line">
                <div className="">Telefon: +48 667 522 501</div>
                <div className="">Email: rejestracja@kursy-fmf.pl</div>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    );
  }
}

export default Footer;
