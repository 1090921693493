import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import PaymentDetails from "../components/payment-details";

const PaymentInfo = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  return (
    <div className="payment-info">
      <div className="title"></div>
      <div className="inner">
        <Container>
          <Row>
            <h2>Szczegóły płatności</h2>
          </Row>
          <Row>
            <PaymentDetails registrationId={id}></PaymentDetails>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default PaymentInfo;
