import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

const PaymentError = () => {
  return (
    <div className="payment-error">
      <div className="title"></div>
      <div className="inner">
        <Container>
          <Row>
            <h2>Błąd</h2>
            <h4>Wystąpił błąd przetwarzania płatności.</h4>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default PaymentError;
