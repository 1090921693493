import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

class Contact extends Component {
  render() {
    return (
      <div className="contact">
        <div className="title"></div>
        <div className="inner">
          <Container>
            <Row>
              <h2>Kontakt</h2>
            </Row>
            <div className="details">
              <Row>Katarzyna Stawisińska</Row>
              <Row>Telefon: +48 667 522 501</Row>
              <Row>Email: rejestracja@kursy-fmf.pl</Row>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}

export default Contact;
