import React, { Component } from "react";
import htmlParser from "html-react-parser";

class CoursePrice extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="coursePrice">
        <h2>Cena</h2>
        <div>{htmlParser(this.props.priceDescription)}</div>
      </div>
    );
  }
}

export default CoursePrice;
