import React from "react";

const Certificate = () => {
  return (
    <div className="certificate">
      <h2>Certyfikat</h2>
      Uczestnicy otrzymują Certyfikat uczestnictwa w Konferencji pod auspicjami
      The Fetal Medicine Foundation oraz 40 pkt do Certyfikatu Umiejętności
      PTGiP w zakresie wykonywania Badań Prenatalnych/ Certyfikatu Podstawowego
    </div>
  );
};

export default Certificate;
