import React from "react";
import { useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import PaymentForm from "../components/payment-form";
import { useSearchParams } from "react-router-dom";

const Payment = () => {
  let registrationId = useLocation().state;
  const [searchParams] = useSearchParams();

  if (registrationId === null) {
    const id = searchParams.get("id");
    registrationId = id;
  }
  return (
    <div className="payment">
      <div className="title"></div>
      <div className="inner">
        <Container>
          <Row>
            <h2>Trwa przekierowanie do systemu płatności...</h2>
            <h6>
              (Jeżeli nie nastąpi przekierowanie w ciagu 3sek, naciśnij poniższy
              przycisk)
            </h6>
          </Row>
          <Row>
            <PaymentForm registrationId={registrationId} />
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Payment;
