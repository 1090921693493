import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useSearchParams } from "react-router-dom";

class RegistrationDetails extends Component {
  render() {
    return (
      <div className="registration-details">
        <div className="title"></div>
        <div className="inner">
          <Container>
            <Row>
              <h2>Szczegóły uczestnictwa</h2>
            </Row>
            <Row>...</Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default RegistrationDetails;
