import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

class RegistrationSuccess extends Component {
  render() {
    return (
      <div className="registration-success">
        <div className="title"></div>
        <div className="inner">
          <Container>
            <Row>
              <h2>Dziękujemy</h2>
            </Row>
            <Row>
              <h4>Formularz rejestracyjny został wysłany.</h4>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default RegistrationSuccess;
