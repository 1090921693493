import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

class Policy extends Component {
  render() {
    return (
      <div className="policy">
        <div className="title"></div>
        <div className="inner">
          <Container>
            <Row>
              <h2>Polityka prywatności</h2>
            </Row>
            <Row>
              <p>
                <strong>
                  Polityka prywatności i wykorzystywania plików „cookies” w
                  Serwisie internetowym kursy-fmf.pl{" "}
                </strong>
              </p>
              <p>
                <strong>Informacje ogólne</strong>
              </p>
              <p>
                Niniejszy dokument określa zasady Polityki prywatności w
                Serwisie internetowym kursy-fmf.pl (dalej jako "Serwis
                Internetowy"). Administratorem Serwisu internetowego jest
                Niepubliczny Zakład Opieki Zdrowotnej " MEDYK " Waldemar
                Litwiński, zarejestrowany pod adresem ul. Chopina 43, 87-800
                Włocławek, NIP 8881023168, REGON 005885339.
              </p>
              <p>
                Wyrazy użyte z dużej litery mają znaczenie nadane im w
                regulaminie niniejszego Serwisu internetowego.
              </p>
              <p>
                Dane osobowe zbierane przez Administratora Serwisu Internetowego
                są przetwarzane zgodnie z przepisami Rozporządzenia Parlamentu
                Europejskiego i Rady (UE) 2016/679 z 27.04.2016 r. w sprawie
                ochrony osób fizycznych w związku z przetwarzaniem danych
                osobowych i w sprawie swobodnego przepływu takich danych oraz
                uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie
                danych) (Dz. Urz. UE L 119, s. 1), zwanego dalej: RODO.
              </p>
              <p>
                Administrator Serwisu internetowego dokłada szczególnych starań
                w celu ochrony prywatności i informacji mu przekazanych, a
                dotyczących Użytkowników Serwisu internetowego. Administrator z
                należytą starannością dobiera i stosuje odpowiednie środki
                techniczne, w tym o charakterze programistycznym i
                organizacyjnym, zapewniające ochronę przetwarzanych danych, w
                szczególności zabezpiecza dane przed ich udostępnieniem osobom
                nieupoważnionym, ujawnieniem, utraceniem i zniszczeniem,
                nieuprawnioną modyfikacją, jak również przed ich przetwarzaniem
                z naruszeniem obowiązujących przepisów prawa.
              </p>
              <p>
                Adresatem możliwości korzystania z Usług dostępnych na stronie
                internetowej nie są dzieci poniżej 16 roku życia. Administrator
                danych osobowych nie przewiduje celowego gromadzenia danych
                dotyczących dzieci poniżej 16 roku życia.
              </p>
              <p>
                <strong>Dane osobowe</strong>
              </p>
              <p>
                <strong>Administrator danych osobowych</strong>
              </p>
              <p>Administratorem Twoich danych osobowych jest:</p>
              <p>
                Niepubliczny Zakład Opieki Zdrowotnej " MEDYK " Waldemar
                Litwiński, zarejestrowany pod adresem ul. Chopina 43, 87-800
                Włocławek, NIP 8881023168, REGON 005885339.
              </p>
              <p>
                W sprawie Twoich danych osobowych możesz skontaktować się z
                Administratorem danych osobowych za pomocą:
              </p>
              <ul>
                <li>poczty elektronicznej: medyk.wloclawek@gmail.com,</li>
                <li>poczty tradycyjnej: ul. Chopina 43; 87–800 Włocławek,</li>
                <li>telefonu: +48 885 230 200.</li>
              </ul>
              <p>
                <strong>
                  Cele i podstawy prawne przetwarzania danych osobowych
                </strong>
              </p>
              <p>
                Administrator danych osobowych przetwarza Twoje dane osobowe w
                następujących celach i zakresie:
              </p>
              <ul>
                <li>
                  w celu Rejestracji na Konferencję przetwarzamy Twoje dane
                  podane w formularzu Rejestracji;
                </li>
                <li>
                  w celu świadczenia Usług niewymagających Rejestracji, tj.
                  przeglądanie stron www Serwisu internetowego, wyszukiwarka
                  treści czy Usług, przetwarzamy dane osobowe dotyczące Twojej
                  aktywności w Serwisie internetowym, tzn. dane dotyczące
                  przeglądanych przez Ciebie treści czy Usług, dane dotyczące
                  sesji Twojego urządzenia, systemu operacyjnego, przeglądarki,
                  lokalizacji oraz unikalnego ID, adres IP;
                </li>
                <li>
                  w celu statystyk korzystania z poszczególnych funkcjonalności
                  dostępnych w Serwisie internetowym, ułatwienia korzystania z
                  Serwisu internetowego oraz zapewnienia bezpieczeństwa
                  informatycznego Serwisu internetowego, przetwarzamy dane
                  osobowe dotyczące Twojej aktywności w Serwisie internetowym
                  oraz ilości czasu spędzanego na każdej z podstron w Serwisie
                  internetowym, Twojej historii wyszukiwania, lokalizacji,
                  adresie IP, ID urządzenia, danych dotyczących Twojej
                  przeglądarki internetowej oraz systemu operacyjnego;
                </li>
                <li>
                  w celu ustalenia, dochodzenia i egzekucji roszczeń oraz obrony
                  przed roszczeniami w postępowaniu sądowym i innymi organami
                  egzekucyjnymi, możemy przetwarzać Twoje dane osobowe podane
                  przy rejestracji w Serwisie, zamówieniu usług oraz inne dane
                  niezbędne do udowodnienia istnienia roszczenia lub które
                  wynikają z wymogu prawnego, nakazu sądowego lub innej
                  procedury prawnej;
                </li>
                <li>
                  w celu rozpatrywania reklamacji, skarg i wniosków oraz
                  odpowiedzi na pytania Użytkowników, przetwarzamy podane przez
                  Ciebie dane osobowe w reklamacjach skargach i wnioskach, czy w
                  celu udzielenia odpowiedzi na pytania zawarte w innej formie
                  oraz niektóre dane osobowe podane przez Ciebie przy
                  Rejestracji, jak również dane dotyczące świadczonych przez nas
                  Usług będące przyczyną reklamacji, skargi czy wniosku oraz
                  dane zawarte w dokumentach dołączonych do reklamacji, skarg i
                  wniosków;
                </li>
                <li>
                  w celu badania rynku i opinii przez nas lub naszych partnerów,
                  tj. informacje o usługach, Twoje dane podane przy Rejestracji
                  lub podczas korzystania z usług, adres e-mail. Dane zebrane w
                  ramach badania rynku i badania opinii nie są przez nas
                  wykorzystywane do celów reklamowych. Dokładne wskazówki podane
                  są w informacji o danej ankiecie lub w miejscu, w którym
                  wprowadzasz swoje dane;
                </li>
                <li>
                  w celu marketingu naszych Usług oraz usług naszych partnerów,
                  w tym remarketingu, w tym celu przetwarzamy dane osobowe
                  podane przez Ciebie przy Rejestracji, dane dotyczące Twojej
                  aktywności w Serwisie internetowym w tym aktywności, które są
                  rejestrowane i przechowywane za pośrednictwem plików cookies,
                  a w szczególności historia aktywności, zamówionych usług,
                  historia wyszukiwania, kliknięcia w Serwisie internetowym,
                  historia i Twoja aktywność związana z naszą komunikacją z
                  Tobą. W przypadku remarketingu, wykorzystujemy dane o Twojej
                  aktywności w celu dotarcia do Ciebie z naszymi komunikatami
                  marketingowymi poza Serwisem internetowym i korzystamy w tym
                  celu z usług zewnętrznych dostawców. Usługi te polegają na
                  wyświetlaniu naszych komunikatów na stronach internetowych
                  innych niż Serwisu internetowego. Szczegóły na ten temat
                  znajdziesz w zapisach dotyczących Plików cookies.
                </li>
              </ul>
              <p>
                <strong>Kategorie odnośnych danych osobowych</strong>
              </p>
              <p>
                Administrator danych osobowych przetwarza następujące kategorie
                odnośnych danych osobowych:
              </p>
              <ul>
                <li>dane kontaktowe;</li>
                <li>dane dotyczące aktywności w Serwisie internetowym;</li>
                <li>dane wymagane przy Rejestracji;</li>
                <li>dane dotyczące reklamacji skarg i wniosków;</li>
                <li>dane dotyczące usług marketingowych.</li>
              </ul>
              <p>
                <strong>Dobrowolność podania danych osobowych</strong>
              </p>
              <p>
                Podanie przez Ciebie wymaganych danych osobowych jest dobrowolne
                i stanowi warunek świadczenia usług przez Administratora danych
                osobowych za pośrednictwem Serwisu internetowego.
              </p>
              <p>
                <strong>Czas przetwarzania danych</strong>
              </p>
              <p>
                Dane osobowe będą przetwarzane przez okres niezbędny do
                realizacji usług, działań marketingowych oraz innych usług
                wykonywanych dla Użytkownika. Dane osobowe będą usunięte w
                następujących przypadkach:
              </p>
              <ul>
                <li>
                  kiedy osoba, której dane dotyczą zwróci się o ich usunięcie
                  lub wycofa udzieloną zgodę;
                </li>
                <li>
                  kiedy osoba, której dane dotyczą nie podejmuje działań przez
                  ponad 10 lat (kontakt nieaktywny);
                </li>
                <li>
                  po uzyskaniu informacji, że przechowywane dane są nieaktualne
                  lub niedokładne.
                </li>
              </ul>
              <p>
                Niektóre dane w zakresie: adres e-mail, imię i nazwisko, mogą
                być przechowywane przez okres kolejnych 3 lat dla celów
                dowodowych, rozpatrywania reklamacji, skarg oraz roszczeń
                związanych z usługami świadczonymi przez Serwis internetowy –
                dane te nie będą wykorzystywane do celów marketingowych.
              </p>
              <p>
                Dane dotyczące Użytkowników niezalogowanych przechowujemy przez
                czas odpowiadający cyklowi życia zapisanych na urządzeniach
                plików cookies lub do czasu ich usunięcia w urządzeniu
                Użytkownika przez Użytkownika.
              </p>
              <p>
                Twoje dane osobowe dotyczące preferencji, zachowań i wyboru
                treści marketingowych mogą być wykorzystywane jako podstawa do
                podjęcia zautomatyzowanych decyzji w celu określenia możliwości
                sprzedażowych Serwisie internetowego.
              </p>
              <p>
                <strong>Odbiorcy danych osobowych</strong>
              </p>
              <p>
                Przekazujemy Twoje dane osobowe następującym kategoriom
                odbiorców:
              </p>
              <ul>
                <li>
                  organom państwowym, np. prokuratura, Policja, PUODO, Prezes
                  UOKiK, jeżeli się o to do nas zwrócą,
                </li>
              </ul>
              <p>
                <strong>Prawa osoby, której dane dotyczą</strong>
              </p>
              <p>Na podstawie RODO masz prawo do:</p>
              <ul>
                <li>żądania dostępu do swoich danych osobowych;</li>
                <li>żądania sprostowania swoich danych osobowych;</li>
                <li>żądania usunięcia swoich danych osobowych;</li>
                <li>żądania ograniczenia przetwarzania danych osobowych;</li>
                <li>
                  wniesienia sprzeciwu wobec przetwarzania danych osobowych;
                </li>
                <li>żądania przenoszenia danych osobowych.</li>
              </ul>
              <p>
                Administrator danych osobowych bez zbędnej zwłoki – a w każdym
                razie w terminie miesiąca od otrzymania żądania – udziela Ci
                informacji o działaniach podjętych w związku ze zgłoszonym przez
                Ciebie żądaniem. W razie potrzeby termin miesięczny może być
                przedłużony o kolejne dwa miesiące z uwagi na skomplikowany
                charakter żądania lub liczbę żądań.
              </p>
              <p>
                W każdym wypadku Administrator danych osobowych poinformuje Cię
                o takim przedłużeniu w terminie miesiąca od otrzymania żądania z
                podaniem przyczyn opóźnienia.
              </p>
              <p>
                <strong>
                  Prawo dostępu do danych osobowych (art. 15 RODO)
                </strong>
              </p>
              <p>
                Masz prawo uzyskania od Administratora danych osobowych
                informacji czy Twoje dane osobowe są przetwarzane.
              </p>
              <p>
                Jeżeli Administrator przetwarza Twoje dane osobowe masz prawo
                do:
              </p>
              <ul>
                <li>dostępu do danych osobowych;</li>
                <li>
                  uzyskania informacji o celach przetwarzania, kategoriach
                  przetwarzanych danych osobowych, o odbiorcach lub kategoriach
                  odbiorców tych danych, planowanym okresie przechowywania
                  Twoich danych lub o kryteriach ustalania tego okresu, o
                  prawach przysługujących Ci na mocy RODO oraz o prawie
                  wniesienia skargi do organu nadzorczego, o źródle tych danych,
                  o zautomatyzowanym podejmowaniu decyzji, w tym o profilowaniu
                  oraz o zabezpieczeniach stosowanych w związku z przekazaniem
                  tych danych poza Unię Europejską;
                </li>
                <li>uzyskania kopii swoich danych osobowych.</li>
              </ul>
              <p>
                Jeśli chcesz zażądać dostępu do swoich danych osobowych zgłoś
                swoje żądanie na adres: medyk.wloclawek@gmail.com.
              </p>
              <p>
                <strong>
                  Prawo do sprostowania danych osobowych (art. 16 RODO)
                </strong>
              </p>
              <p>
                Jeżeli Twoje dane osobowe są nieprawidłowe masz prawo żądania od
                Administratora niezwłocznego sprostowania Twoich danych
                osobowych.
              </p>
              <p>
                Masz też prawo do żądania od Administratora uzupełnienia Twoich
                danych osobowych.
              </p>
              <p>
                Jeśli chcesz zażądać sprostowania danych osobowych lub ich
                uzupełnienia, zgłoś swoje żądanie na adres:
                medyk.wloclawek@gmail.com.
              </p>
              <p>
                Jeżeli dokonałeś rejestracji w Serwisie Internetowym swoje dane
                osobowe możesz sprostować i uzupełniać samodzielnie po
                zalogowaniu się w Serwisie Internetowym.
              </p>
              <p>
                <strong>
                  Prawo do usunięcia danych osobowych, tzw. “prawo do bycia
                  zapomnianym” (art. 17 RODO)
                </strong>
              </p>
              <p>
                Masz prawo żądania od Administratora danych osobowych usunięcia
                swoich danych osobowych, gdy:
              </p>
              <ul>
                <li>
                  Twoje dane osobowe przestały być niezbędne do celów, w których
                  zostały zebrane lub w inny sposób przetwarzane;
                </li>
                <li>
                  wycofałeś określoną zgodę, w zakresie w jakim dane osobowe
                  były przetwarzane w oparciu o Twoją zgodę;
                </li>
                <li>
                  Twoje dane osobowe były przetwarzane niezgodnie z prawem;
                </li>
                <li>
                  wniosłeś/wniosłaś sprzeciw wobec przetwarzania Twoich danych
                  osobowych na potrzeby marketingu bezpośredniego, w tym
                  profilowania, w zakresie w jakim przetwarzanie danych
                  osobowych jest związane z marketingiem bezpośrednim;
                </li>
                <li>
                  wniosłeś/wniosłaś sprzeciw wobec przetwarzania Twoich danych
                  osobowych w związku z przetwarzaniem niezbędnym dla wykonania
                  zadania realizowanego w interesie publicznym lub przetwarzania
                  niezbędnego dla celów wynikających z prawnie uzasadnionych
                  interesów realizowanych przez Administratora danych osobowych
                  lub stronę trzecią.
                </li>
              </ul>
              <p>
                Pomimo zgłoszenia żądania usunięcia danych osobowych
                Administrator danych osobowych może przetwarzać Twoje dane dalej
                w celu ustalenia, dochodzenia lub obrony roszczeń o czym
                zostaniesz poinformowany/poinformowana.
              </p>
              <p>
                Jeśli chcesz zażądać usunięcia swoich danych osobowych zgłoś
                swoje żądanie na adres: medyk.wloclawek@gmail.com.
              </p>
              <p>
                <strong>
                  Prawo do zgłoszenia żądania ograniczenia przetwarzania danych
                  osobowych (art. 18 RODO)
                </strong>
              </p>
              <p>
                Masz prawo do żądania ograniczenia przetwarzania Twoich danych
                osobowych, gdy:
              </p>
              <ul>
                <li>
                  kwestionujesz prawidłowość swoich danych osobowych –
                  Administrator danych osobowych ograniczy przetwarzanie Twoich
                  danych osobowych na czas pozwalający sprawdzić prawidłowość
                  tych danych;
                </li>
                <li>
                  gdy przetwarzanie Twoich danych jest niezgodne z prawem, a
                  zamiast usunięcia danych osobowych zażądasz ograniczenia
                  przetwarzania Twoich danych osobowych;
                </li>
                <li>
                  Twoje dane osobowe przestały być potrzebne do celów
                  przetwarzania, ale są one potrzebne w celu ustalenia,
                  dochodzenia lub obrony Twoich roszczeń;
                </li>
                <li>
                  gdy zgłosiłeś/zgłosiłaś sprzeciw wobec przetwarzania Twoich
                  danych osobowych – do czasu stwierdzenia czy prawnie
                  uzasadnione interesy po stronie Administratora danych
                  osobowych są nadrzędne wobec podstaw wskazanych w Twoim
                  sprzeciwie.
                </li>
              </ul>
              <p>
                Jeśli chcesz zażądać ograniczenia przetwarzania swoich danych
                osobowych zgłoś swoje żądanie na adres:
                medyk.wloclawek@gmail.com.
              </p>
              <p>
                <strong>
                  Prawo do sprzeciwu wobec przetwarzania danych osobowych (art.
                  21 RODO)
                </strong>
              </p>
              <p>
                Masz prawo w dowolnym momencie wnieść sprzeciw wobec
                przetwarzania Twoich danych osobowych, w tym profilowania, w
                związku z:
              </p>
              <ul>
                <li>
                  przetwarzaniem niezbędnym dla wykonania zadania realizowanego
                  w interesie publicznym lub przetwarzania niezbędnego dla celów
                  wynikających z prawnie uzasadnionych interesów realizowanych
                  przez Administratora danych osobowych lub stronę trzecią;
                </li>
                <li>przetwarzaniem na potrzeby marketingu bezpośredniego.</li>
              </ul>
              <p>
                Jeśli chcesz zgłosić sprzeciw wobec przetwarzania Twoich danych
                osobowych, zgłoś swoje żądanie na adres:
                medyk.wloclawek@gmail.com.
              </p>
              <p>
                <strong>
                  Prawo do żądania przenoszenia danych osobowych (art. 20 RODO)
                </strong>
              </p>
              <p>
                Masz prawo do otrzymania od Administratora danych osobowych
                swoich danych osobowych w ustrukturyzowanym, powszechnie
                używanym formacie nadającym się do odczytu maszynowego oraz
                przesłać je innemu administratorowi danych osobowych.
              </p>
              <p>
                Możesz również zażądać, aby to Administrator danych osobowych
                przesłał bezpośrednio Twoje dane osobowe innemu administratorowi
                (o ile jest to technicznie możliwe).
              </p>
              <p>
                Jeśli chcesz zażądać przeniesienia swoich danych osobowych zgłoś
                swoje żądanie na adres: medyk.wloclawek@gmail.com.
              </p>
              <p>
                <strong>Prawo do cofnięcia zgody</strong>
              </p>
              <p>
                Możesz cofnąć udzieloną zgodę na przetwarzanie swoich danych
                osobowych w dowolnym momencie.
              </p>
              <p>
                Cofnięcie zgody na przetwarzanie danych osobowych nie ma wpływu
                na zgodność z prawem przetwarzania dokonanego na podstawie
                Twojej zgody przed jej cofnięciem.
              </p>
              <p>
                Jeśli chcesz cofnąć zgodę na przetwarzanie swoich danych
                osobowych zgłoś swoje żądanie na adres:
                medyk.wloclawek@gmail.com lub skorzystaj z odpowiednich
                funkcjonalności w Serwisie.
              </p>
              <p>
                <strong>Skarga do organu nadzorczego</strong>
              </p>
              <p>
                Jeżeli sądzisz, że przetwarzanie Twoich danych osobowych narusza
                RODO masz prawo złożenia skargi do organu nadzorczego, w
                szczególności w państwie członkowskim swojego zwykłego pobytu,
                swojego miejsca pracy lub miejsca popełnienia domniemanego
                naruszenia.
              </p>
              <p>
                W Polsce organem nadzorczym w rozumieniu RODO jest Prezes Urzędu
                Ochrony Danych Osobowych (PUODO).
              </p>
              <p>
                <strong>Pliki „cookies”</strong>
              </p>
              <p>
                <strong>Informacje ogólne</strong>
              </p>
              <p>
                Podczas przeglądania stron internetowych Serwisu internetowego
                są używane pliki „cookies", zwane dalej Cookies, czyli
                niewielkie informacje tekstowe, które są zapisywane w Twoim
                urządzeniu końcowym w związku korzystaniem ze Serwisu
                internetowego. Ich stosowanie ma na celu poprawne działanie
                stron internetowych Serwisu Internetowego.
              </p>
              <p>
                Pliki te pozwalają zidentyfikować oprogramowanie wykorzystywane
                przez Ciebie i dostosować Serwis internetowy indywidualnie do
                Twoich potrzeb.
              </p>
              <p>
                Pliki „cookies" zazwyczaj zawierają nazwę domeny, z której
                pochodzą, czas przechowywania ich na urządzeniu oraz przypisaną
                wartość.
              </p>
              <p>
                <strong>Bezpieczeństwo</strong>
              </p>
              <p>
                Stosowane przez nas pliki „cookies" są bezpieczne dla Twoich
                urządzeń. W szczególności nie jest możliwe przedostanie się do
                Twoich urządzeń poprzez pliki „cookies” wirusów lub innego
                niechcianego oprogramowania lub oprogramowania złośliwego.
              </p>
              <p>
                <strong>Rodzaje plików „cookies”</strong>
              </p>
              <p>Stosujemy dwa rodzaje plików cookies:</p>
              <ul>
                <li>
                  Cookies sesyjne: są przechowywane na Twoim urządzeniu i
                  pozostają tam do momentu zakończenia sesji danej przeglądarki.
                  Zapisane informacje są wówczas trwale usuwane z pamięci
                  Twojego urządzenia. Mechanizm cookies sesyjnych nie pozwala na
                  pobieranie jakichkolwiek danych osobowych ani żadnych
                  informacji poufnych z Twojego urządzenia.
                </li>
                <li>
                  Cookies trwałe: są przechowywane na Twoim urządzeniu i
                  pozostają tam do momentu ich skasowania. Zakończenie sesji
                  danej przeglądarki lub wyłączenie urządzenia nie powoduje ich
                  usunięcia z Twojego urządzenia. Mechanizm cookies trwałych nie
                  pozwala na pobieranie jakichkolwiek danych osobowych ani
                  żadnych informacji poufnych z Twojego urządzenia.
                </li>
              </ul>
              <p>
                <strong>Cele</strong>
              </p>
              <p>
                Wykorzystujemy także pliki "cookies" podmiotów zewnętrznych w
                następujących celach:
              </p>
              <ul>
                <li>
                  tworzenia statystyk – pomagających zrozumieć sposób
                  korzystania przez Użytkowników z Serwisu internetowego, co
                  umożliwia ulepszanie jego struktury i zawartości za
                  pośrednictwem narzędzi analitycznych Google Analytics – przez
                  Google Inc. z siedzibą w USA,
                </li>
                <li>
                  określania profilu Użytkowników – a następnie wyświetlania
                  dopasowanych pod jego kątem materiałów w sieciach reklamowych,
                  z wykorzystaniem narzędzia internetowej reklamy Google Adwords
                  – przez Google Inc. z siedzibą w USA,
                </li>
                <li>
                  popularyzacji Serwisu internetowego za pomocą serwisu
                  społecznościowego Facebook.com – przez Facebook Inc. z
                  siedzibą w USA lub Facebook Ireland z siedzibą w Irlandii,
                </li>
              </ul>
              <p>
                Aby poznać zasady korzystania z Cookies, zalecamy zapoznanie się
                politykami prywatności wyżej wskazanych firm.
              </p>
              <p>
                Cookies mogą być wykorzystane przez sieci reklamowe, w
                szczególności sieć Google, do wyświetlenia reklam dopasowanych
                do Twoich preferencji. W tym celu mogą zostać zachowane
                informacje o sposobie poruszania się przez Ciebie w sieci lub
                czasie skorzystania ze strony internetowej.
              </p>
              <p>
                Aby przeglądać i edytować informacje o Twoich preferencjach,
                gromadzonych przez sieć reklamową Google, możesz skorzystać z
                narzędzia zamieszczonego pod linkiem
                https://www.google.com/ads/preferences/.
              </p>
              <p>
                Za pomocą ustawień przeglądarki internetowej lub za pomocą
                konfiguracji usługi, możesz samodzielnie i w każdym czasie
                zmienić ustawienia dotyczące Cookies, określając warunki ich
                przechowywania i uzyskiwania dostępu przez Cookies do Twojego
                urządzenia. Ustawienia te możesz zmienić tak, aby blokować
                automatyczną obsługę Cookies w ustawieniach przeglądarki
                internetowej bądź informować o ich każdorazowym zamieszczeniu na
                Twoim urządzeniu. Szczegółowe informacje o możliwości i
                sposobach obsługi Cookies dostępne są w ustawieniach Twojego
                oprogramowania (przeglądarki internetowej).
              </p>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default Policy;
