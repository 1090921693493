import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Footer from "./components/footer";
import Header from "./components/header";

import Home from "./pages/home";
import Registration from "./pages/registration";
import RegistrationSuccess from "./pages/registration-success";
import RegistrationError from "./pages/registration-error";
import Policy from "./pages/policy";
import Contact from "./pages/contact";
import Gallery from "./pages/gallery";
import Payment from "./pages/payment";
import PaymentSuccess from "./pages/payment-success";
import PaymentError from "./pages/payment-error";
import LastCourses from "./pages/last-courses";
import RegistrationDetails from "./pages/registration-details";
import PaymentInfo from "./pages/payment-info";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />}></Route>
        <Route path="/rejestracja" element={<Registration />}></Route>
        <Route
          path="/rejestracja/szczegoly"
          element={<RegistrationDetails />}
        ></Route>
        <Route path="/potwierdzenie" element={<RegistrationSuccess />}></Route>
        <Route path="/niepowodzenie" element={<RegistrationError />}></Route>
        <Route path="/polityka-prywatnosci" element={<Policy />}></Route>
        <Route path="/kontakt" element={<Contact />}></Route>
        {/* <Route path="/galeria" element={<Gallery />}></Route> */}
        <Route path="/platnosc" element={<Payment />}></Route>
        <Route path="/platnosc/status" element={<PaymentSuccess />}></Route>
        <Route path="/platnosc/niepoprawna" element={<PaymentError />}></Route>
        <Route path="/platnosc/szczegoly" element={<PaymentInfo />}></Route>
        <Route path="/poprzednie-edycje" element={<LastCourses />}></Route>
        <Route path="*" element={<Home />}></Route>
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
