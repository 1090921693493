import React, { Component } from "react";
import htmlParser from "html-react-parser";

class Where extends Component {
  render() {
    return (
      <div>
        <div className="where">
          <h2>Miejsce</h2>
          <div>{this.props.description}</div>
          <div>
            {this.props.address} {this.props.details}
          </div>
        </div>
        <iframe
          width="100%"
          height="500"
          frameBorder="0"
          marginWidth="0"
          title={this.props.description}
          src={htmlParser(
            `https://maps.google.com/maps?hl=pl&amp;q=${this.props.address}&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed`
          )}
        />
      </div>
    );
  }
}

export default Where;
