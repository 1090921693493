import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import Spinner from "react-bootstrap/Spinner";

import axiosInstance from "../common/axios";

class PaymentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      status: "",
      result: "",
      description: "",
      amount: "",
    };
  }

  getPaymentStatus = () => {
    axiosInstance
      .get("payment/status", {
        params: { registrationId: this.props.registrationId },
      })
      .then(
        (result) => {
          this.setState({
            status: result.data.status,
            result: result.data.result,
            description: result.data.description,
            amount: result.data.amount,
            isLoaded: true,
          });
        },
        (error) => {
          window.location.replace("/");
        }
      );
  };

  componentDidMount() {
    this.getPaymentStatus();
  }
  render() {
    const { status, result, description, amount, isLoaded } = this.state;
    const id = this.props.registrationId;
    return (
      <div className="payment-details">
        {!isLoaded && <Spinner animation="border" />}
        {isLoaded && (
          <Container>
            <Row>
              <Col>
                <h3>Witaj {description}</h3>
                <h4>
                  Status Twojej płatności: &nbsp;
                  {status === -1 && <Badge bg="danger">{result}</Badge>}
                  {status === 0 && <Badge bg="danger">{result}</Badge>}
                  {status === 1 && <Badge bg="success">{result}</Badge>}
                  {status === 2 && <Badge bg="secondary">{result}</Badge>}
                </h4>
              </Col>
            </Row>
            <Row>
              <Col>
                <h4>Suma: {amount} zł</h4>
              </Col>
            </Row>
            {status === 0 && (
              <div>
                <Row>
                  <Col xs="8">
                    <div>
                      <Button href={"/platnosc?id=" + id}>Zapłać online</Button>
                    </div>
                  </Col>
                  <Col></Col>
                </Row>
                <Row>
                  <Col>
                    <div className="security">
                      * Bezpieczeństwo płatności zapewnia Tpay
                    </div>
                    <img
                      id="tpay-image"
                      src="https://tpay.com/img/banners/tpay_com_zaufane_platnosci_bar.svg"
                      alt="Tpay - zaufane płatności"
                      title="Tpay - zaufane płatności"
                    />
                  </Col>
                </Row>
              </div>
            )}
          </Container>
        )}
      </div>
    );
  }
}

export default PaymentDetails;
