import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import axiosInstance from "../common/axios";
import Spinner from "react-bootstrap/Spinner";
import Nav from "react-bootstrap/Nav";
import Col from "react-bootstrap/Col";
import Program from "../components/program";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

class LastCourses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
    };
  }
  fetchCourse = () => {
    axiosInstance.get("course/getAllVisible").then(
      (result) => {
        this.setState({
          isLoaded: true,
          items: result.data,
        });
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );
  };
  componentDidMount() {
    this.fetchCourse();
  }

  render() {
    const { items, isLoaded } = this.state;
    return (
      <div className="last-courses">
        <div className="title"></div>
        <div className="inner">
          <Container>
            <Row>
              <h2>Poprzednie edycje</h2>
              {!isLoaded && <Spinner animation="border" />}
            </Row>
            <Tab.Container id="left-tabs-example" defaultActiveKey="0">
              <Row className="tab-edition">
                <Col sm={3} className="expandCol">
                  <Nav variant="pills" className="flex-column">
                    {items.map((item, index) => (
                      <Nav.Item>
                        <Nav.Link eventKey={index}>
                          {item.name} ({item.dateDescription})
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                </Col>
                <Col sm={9}>
                  <Tab.Content>
                    {items.map((item, index) => (
                      <Tab.Pane eventKey={index}>
                        <Tabs fill>
                          <Tab title="Program" eventKey="Program">
                            <h2>{item.programName}</h2>
                            <h5>
                              {item.name} ({item.dateDescription})
                            </h5>
                            <Program program={item.program}></Program>
                          </Tab>
                          <Tab title="Galeria" eventKey="Galeria">
                            <iframe
                              src={item.url}
                              height="526"
                              width="100%"
                              allowFullScreen
                            ></iframe>
                          </Tab>
                        </Tabs>
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Container>
        </div>
      </div>
    );
  }
}

export default LastCourses;
