import { Component } from "react";

class CourseDate extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="courseDates">
        <h2>Termin</h2>
        {this.props.dateVisible && <span>{this.props.dateDescription}</span>}
        {this.props.dateVisible === false && (
          <span>(brak aktualnego terminu)</span>
        )}
      </div>
    );
  }
}

export default CourseDate;
