import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

import axiosInstance from "../common/axios";

class PaymentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {},
    };
  }

  fillPaymentForm = () => {
    axiosInstance
      .get("payment", {
        params: { registrationId: this.props.registrationId },
      })
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            form: result.data,
          });
          this.postPaymentForm();
        },
        (error) => {
          window.location.replace("/platnosc/niepoprawna");
        }
      );
  };

  postPaymentForm = () => {
    document.getElementById("tpay-payment-submit").click();
  };

  componentDidMount = () => {
    if (this.props.registrationId === null) window.location.replace("/");
    this.fillPaymentForm();
  };

  render() {
    const { form } = this.state;
    return (
      <form
        id="tpay-payment"
        className="tpay-form"
        action="https://secure.tpay.com"
        method="POST"
        acceptCharset="UTF-8"
      >
        <input type="hidden" name="amount" value={form.amount} />
        <input type="hidden" name="description" value={form.description} />
        <input type="hidden" name="language" value={form.language} />
        <input type="hidden" name="crc" value={form.crc} />
        <input type="hidden" name="email" value={form.email} />
        <input type="hidden" name="name" value={form.name} />
        <input type="hidden" name="phone" value={form.phone} />
        <input type="hidden" name="return_url" value={form.return_url} />
        <input type="hidden" name="return_error" value={form.return_error} />
        <input type="hidden" name="online" value={form.online} />
        <input type="hidden" name="md5sum" value={form.md5sum} />
        <input type="hidden" name="id" value={form.id} />
        <input
          type="hidden"
          name="merchant_description"
          value={form.merchant_description}
        />
        <Button className="btn" type="submit" id="tpay-payment-submit">
          Zapłać z Tpay
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        </Button>
      </form>
    );
  }
}

export default PaymentForm;
