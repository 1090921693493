import React, { Component } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";

import Container from "react-bootstrap/Container";

class Header extends Component {
  render() {
    return (
      <div className="header">
        <Navbar variant="dark" expand="lg">
          <Container>
            <Navbar.Brand href="/">
              Szkolenia Ultrasonograficzne E&M Litwińskie
            </Navbar.Brand>
            <Nav className="justify-content-end">
              <Navbar.Toggle />
              <Navbar.Offcanvas placement="end">
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>Menu</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-end flex-grow-1">
                    <Button size="md" variant="light" href="/rejestracja">
                      Rejestracja
                    </Button>
                    <Button size="md" variant="light" href="/poprzednie-edycje">
                      Poprzednie edycje
                    </Button>
                    <Button size="md" variant="light" href="/kontakt">
                      Kontakt
                    </Button>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Nav>
          </Container>
        </Navbar>
      </div>
    );
  }
}

export default Header;
