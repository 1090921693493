import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Card from "react-bootstrap/Card";

import fmf from "../assets/fmf.png";
import wum from "../assets/wum.png";

class Patrons extends Component {
  render() {
    return (
      <div className="patrons">
        <Container>
          <h2>Patronat</h2>
          <Row className="justify-content-md-center justify-content-sm-center">
            <Col className="item" sm="auto">
              <Card>
                <Card.Body>
                  <Row>
                    <Col xs="2">
                      <Image src={wum}></Image>
                    </Col>
                    <Col>
                      <span className="wum">
                        Warszawski Uniwersytet Medyczny
                        <p> I Katedra Położnictwa i Ginekologii</p>
                      </span>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col className="item" sm="auto">
              <Card>
                <Card.Body>
                  <Image src={fmf}></Image>
                </Card.Body>
              </Card>
            </Col>
            {/* <Col className="item" sm="auto">
              <Card>
                <Card.Body>
                  <Image src={ge}></Image>
                </Card.Body>
              </Card>
            </Col> */}
          </Row>
        </Container>
      </div>
    );
  }
}

export default Patrons;
