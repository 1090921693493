import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

const PaymentSuccess = () => {
  return (
    <div className="payment-success">
      <div className="title"></div>
      <div className="inner">
        <Container>
          <Row>
            <h2>Dziękujemy za rejestrację</h2>
            <h4>
              Status płatności systemu Tpay możesz zweryfikować drogą email.
            </h4>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default PaymentSuccess;
