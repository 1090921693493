import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import RegistrationForm from "../components/registration-form";

const Registration = () => {
  return (
    <div className="registration">
      <div className="title"></div>
      <div className="inner">
        <Container>
          <Row>
            <h2>Rejestracja</h2>
          </Row>
          <Row>
            <RegistrationForm navigate={useNavigate()} />
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Registration;
