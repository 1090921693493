import React, { Component } from "react";
import htmlParser from "html-react-parser";

class Program extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return <div className="program">{htmlParser(this.props.program)}</div>;
  }
}
export default Program;
