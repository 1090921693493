import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";

class Where extends Component {
  render() {
    return (
      <div className="register">
        <Container>
          <Row>
            <Col xs={12} sm={10}>
              <h2>Zarezerwuj swoje miejsce</h2>
            </Col>
            <Col xs={12} sm={2}>
              <Button size="lg" variant="light" href="/rejestracja">
                Rejestracja
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Where;
