import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Card from "react-bootstrap/Card";

import { Formik } from "formik";
import * as Yup from "yup";

import axiosInstance from "../common/axios";

class RegistrationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      courses: [],
      priceCategory0: {},
      priceCategory1: {},
      priceCategory2: {},
      priceItems0: [], //lekarz
      priceItems1: [], //warsztaty
      priceItems2: [], //kolacja
      payment: false,
      companyInvoice: false,
      summaryPrice: 0,
      price0: 0,
      price1: 0,
      price2: 0,
    };
  }

  // fetchCoursePricesByCourseId = (courseId) => {
  //   axiosInstance
  //     .get("coursePrice/getByCourseId", {
  //       params: { courseId: courseId },
  //     })
  //     .then(
  //       (result) => {
  //         this.setState({
  //           isLoaded: true,
  //           priceCategory0: result.data[0].priceCategory,
  //           priceCategory1: result.data[1].priceCategory,
  //           priceCategory2: result.data[2].priceCategory,
  //           priceItems0: result.data[0].priceCategory.priceItems,
  //           priceItems1: result.data[1].priceCategory.priceItems,
  //           priceItems2: result.data[2].priceCategory.priceItems,
  //         });
  //       },
  //       (error) => {
  //         this.setState({
  //           error,
  //         });
  //       }
  //     );
  // };

  fetchCoursePrices = () => {
    axiosInstance.get("coursePrice").then(
      (result) => {
        this.setState({
          isLoaded: true,
          priceCategory0: result.data[0].priceCategory,
          priceCategory1: result.data[1].priceCategory,
          priceCategory2: result.data[2].priceCategory,
          priceItems0: result.data[0].priceCategory.priceItems,
          priceItems1: result.data[1].priceCategory.priceItems,
          priceItems2: result.data[2].priceCategory.priceItems,
        });
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );
  };

  fetchCourses = () => {
    axiosInstance.get("course/getAllActiveShort").then(
      (result) => {
        this.setState({
          courses: result.data,
        });
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );
  };

  fetchPayment = () => {
    axiosInstance.get("payment/enabled").then(
      (result) => {
        this.setState({
          payment: result.data,
        });
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );
  };

  onCompanyInvoiceChange = (e) => {
    this.setState({
      companyInvoice: e.target.checked,
    });
  };

  onPriceChange0 = (e) => {
    var price = e.target.selectedOptions[0].getAttribute("price");
    this.setState(
      {
        price0: price,
      },
      () => {
        this.calculateSummary();
      }
    );
  };

  onPriceChange1 = (e) => {
    var price = e.target.selectedOptions[0].getAttribute("price");
    this.setState(
      {
        price1: price,
      },
      () => {
        this.calculateSummary();
      }
    );
  };

  onPriceChange2 = (e) => {
    var price = e.target.selectedOptions[0].getAttribute("price");
    this.setState(
      {
        price2: price,
      },
      () => {
        this.calculateSummary();
      }
    );
  };

  calculateSummary = () => {
    var summary =
      parseInt(this.state.price0) +
      parseInt(this.state.price1) +
      parseInt(this.state.price2);
    this.setState({
      summaryPrice: summary,
    });
  };

  componentDidMount() {
    this.fetchCourses();
    this.fetchCoursePrices();
    this.fetchPayment();
  }

  render() {
    const {
      isLoaded,
      courses,
      priceCategory0,
      priceCategory1,
      priceCategory2,
      priceItems0,
      priceItems1,
      priceItems2,
      payment,
      companyInvoice,
      summaryPrice,
    } = this.state;
    const navigate = this.props.navigate;
    return (
      <div className="registration-form">
        <Formik
          validationSchema={Yup.object({
            firstName: Yup.string().required(),
            lastName: Yup.string().required(),
            phoneNumber: Yup.string().required(),
            email: Yup.string().email().required(),
            courseId: Yup.string().required(),
            priceItemId0:
              !priceCategory0.personLimited && Yup.string().required(),
            priceItemId1:
              !priceCategory1.personLimited && Yup.string().required(),
            priceItemId2:
              !priceCategory2.personLimited && Yup.string().required(),
            companyName: companyInvoice && Yup.string().required(),
            companyNip: companyInvoice && Yup.string().required(),
            companyStreet: companyInvoice && Yup.string().required(),
            companyPostalCode: companyInvoice && Yup.string().required(),
            companyCity: companyInvoice && Yup.string().required(),
          })}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            axiosInstance({
              method: "post",
              url: "registration",
              data: values,
            })
              .then((response) => {
                if (response.status === 200 && response.data.isValid === true) {
                  navigate(response.data.url, { state: response.data.id });
                } else navigate("/niepowodzenie");
              })
              .catch((error) => {
                navigate("/niepowodzenie");
              });
          }}
          initialValues={{
            firstName: "",
            lastName: "",
            phoneNumber: "",
            courseId: "",
            email: "",
            companyInvoice: false,
            companyName: "",
            companyNip: "",
            companyStreet: "",
            companyPostalCode: "",
            companyCity: "",
            priceItemId0: "",
            priceItemId1: "",
            priceItemId2: "",
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
            isSubmitting,
          }) => (
            <div>
              <Form id="registration-form" onSubmit={handleSubmit}>
                <Row>
                  <Col xs="12" sm="6">
                    <Form.Group as={Col} controlId="firstName">
                      <Form.Label>Imię</Form.Label>
                      <Form.Control
                        type="text"
                        name="firstName"
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.firstName && !errors.firstName}
                        isInvalid={touched.firstName && errors.firstName}
                      />
                      <Form.Control.Feedback type="invalid">
                        Podaj imię
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs="12" sm="6">
                    <Form.Group as={Col} controlId="lastName">
                      <Form.Label>Nazwisko</Form.Label>
                      <Form.Control
                        type="text"
                        name="lastName"
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.lastName && !errors.lastName}
                        isInvalid={touched.lastName && errors.lastName}
                      />
                      <Form.Control.Feedback type="invalid">
                        Podaj nazwisko
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" sm="6">
                    <Form.Group as={Col} controlId="phoneNumber">
                      <Form.Label>Telefon</Form.Label>
                      <Form.Control
                        type="text"
                        name="phoneNumber"
                        value={values.phoneNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.phoneNumber && !errors.phoneNumber}
                        isInvalid={touched.phoneNumber && errors.phoneNumber}
                      />
                      <Form.Control.Feedback type="invalid">
                        Podaj telefon
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs="12" sm="6">
                    <Form.Group as={Col} controlId="email">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.email && !errors.email}
                        isInvalid={touched.email && errors.email}
                      />
                      <Form.Control.Feedback type="invalid">
                        Podaj poprawny email
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" sm="6">
                    {/* {coursePrices.map((i, index) => (
                      <Form.Group as={Col} controlId={"priceItem" + index}>
                        <Form.Label>{priceCategory[index].name}</Form.Label>
                        <Form.Select
                          defaultValue=""
                          name={"priceItem" + index}
                          value={"values.priceItem0" + index}
                          onBlur={handleBlur}
                          isValid={touched.priceItem0 && !errors.priceItem0}
                          isInvalid={touched.priceItem0 && errors.priceItem0}
                          onChange={handleChange}
                        >
                          <option value="">Wybierz...</option>
                          {priceItems[index].map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name} ({item.price} zł)
                            </option>
                          ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                          {priceCategory[index].description}
                        </Form.Control.Feedback>
                      </Form.Group>
                    ))} */}
                    <Card>
                      <Card.Title>Cennik</Card.Title>
                      <Card.Body>
                        {!isLoaded && <Spinner animation="border" />}
                        {priceCategory0.visible && (
                          <Form.Group as={Col} controlId="priceItemId0">
                            <Form.Label>{priceCategory0.name}</Form.Label>
                            {!priceCategory0.personLimited && (
                              <Form.Select
                                defaultValue="0"
                                name="priceItemId0"
                                value={values.priceItemId0}
                                onBlur={handleBlur}
                                isValid={
                                  touched.priceItemId0 && !errors.priceItemId0
                                }
                                isInvalid={
                                  touched.priceItemId0 && errors.priceItemId0
                                }
                                onChange={(e) => {
                                  this.onPriceChange0(e);
                                  handleChange(e);
                                }}
                              >
                                <option value="" price="0">
                                  Wybierz...
                                </option>
                                {priceItems0.map((item) => (
                                  <option
                                    key={item.id}
                                    value={item.id}
                                    price={item.price}
                                  >
                                    {item.name}&nbsp;
                                    {item.priceVisible && `(${item.price} zł)`}
                                  </option>
                                ))}
                              </Form.Select>
                            )}
                            {priceCategory0.personLimited && (
                              <div>{priceCategory0.hiddenDescription}</div>
                            )}

                            <Form.Control.Feedback type="invalid">
                              {priceCategory0.description}
                            </Form.Control.Feedback>
                          </Form.Group>
                        )}
                        {priceCategory1.visible && (
                          <Form.Group as={Col} controlId="priceItemId1">
                            <Form.Label>{priceCategory1.name}</Form.Label>
                            {!priceCategory1.personLimited && (
                              <Form.Select
                                defaultValue="0"
                                name="priceItemId1"
                                value={values.priceItemId1}
                                onBlur={handleBlur}
                                isValid={
                                  touched.priceItemId1 && !errors.priceItemId1
                                }
                                isInvalid={
                                  touched.priceItemId1 && errors.priceItemId1
                                }
                                //onChange={handleChange}
                                onChange={(e) => {
                                  this.onPriceChange1(e);
                                  handleChange(e);
                                }}
                              >
                                <option value="" price="0">
                                  Wybierz...
                                </option>
                                {priceItems1.map((item) => (
                                  <option
                                    key={item.id}
                                    value={item.id}
                                    price={item.price}
                                  >
                                    {item.name}&nbsp;
                                    {item.priceVisible && `(${item.price} zł)`}
                                  </option>
                                ))}
                              </Form.Select>
                            )}
                            {priceCategory1.personLimited && (
                              <div>{priceCategory1.hiddenDescription}</div>
                            )}
                            <Form.Control.Feedback type="invalid">
                              {priceCategory1.description}
                            </Form.Control.Feedback>
                          </Form.Group>
                        )}
                        {priceCategory2.visible && (
                          <Form.Group as={Col} controlId="priceItemId2">
                            <Form.Label>{priceCategory2.name}</Form.Label>
                            {!priceCategory2.personLimited && (
                              <Form.Select
                                defaultValue="0"
                                name="priceItemId2"
                                value={values.priceItemId2}
                                onBlur={handleBlur}
                                isValid={
                                  touched.priceItemId2 && !errors.priceItemId2
                                }
                                isInvalid={
                                  touched.priceItemId2 && errors.priceItemId2
                                }
                                //onChange={handleChange}
                                onChange={(e) => {
                                  this.onPriceChange2(e);
                                  handleChange(e);
                                }}
                              >
                                <option value="" price="0">
                                  Wybierz...
                                </option>
                                {priceItems2.map((item) => (
                                  <option
                                    key={item.id}
                                    value={item.id}
                                    price={item.price}
                                  >
                                    {item.name}&nbsp;
                                    {item.priceVisible && `(${item.price} zł)`}
                                  </option>
                                ))}
                              </Form.Select>
                            )}
                            {priceCategory2.personLimited && (
                              <div>{priceCategory2.hiddenDescription}</div>
                            )}
                            <Form.Control.Feedback type="invalid">
                              {priceCategory2.description}
                            </Form.Control.Feedback>
                          </Form.Group>
                        )}
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col xs="12" sm="6">
                    <Form.Group as={Col} controlId="courseId">
                      <Form.Label>Termin</Form.Label>
                      <Form.Select
                        defaultValue=""
                        name="courseId"
                        value={values.courseId}
                        onBlur={handleBlur}
                        isValid={touched.courseId && !errors.courseId}
                        isInvalid={touched.courseId && errors.courseId}
                        onChange={handleChange}
                        // onChange={(e) => {
                        //   this.fetchCoursePrices(e.target.value);
                        //   handleChange(e);
                        // }}
                      >
                        <option value="">Wybierz...</option>
                        {courses.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.dateDescription} ({item.name})
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Wybierz termin
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Card className="summary">
                      <Card.Title>Suma: {summaryPrice} zł</Card.Title>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Check
                      type="checkbox"
                      id="companyInvoice"
                      label="Chcę otrzymać fakturę"
                      value={values.companyInvoice}
                      onChange={(e) => {
                        this.onCompanyInvoiceChange(e);
                        handleChange(e);
                      }}
                    />
                  </Col>
                </Row>
                {companyInvoice && (
                  <Card>
                    <Card.Title>Dane do faktury</Card.Title>
                    <Card.Body>
                      <Row>
                        <Col xs="12" sm="6">
                          <Form.Group as={Col} controlId="companyName">
                            <Form.Label>Nazwa firmy</Form.Label>
                            <Form.Control
                              type="text"
                              name="companyName"
                              value={values.companyName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isValid={
                                touched.companyName && !errors.companyName
                              }
                              isInvalid={
                                touched.companyName && errors.companyName
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              Podaj nazwę firmy
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col xs="12" sm="6">
                          <Form.Group as={Col} controlId="companyNip">
                            <Form.Label>NIP</Form.Label>
                            <Form.Control
                              type="text"
                              name="companyNip"
                              value={values.companyNip}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isValid={touched.companyNip && !errors.companyNip}
                              isInvalid={
                                touched.companyNip && errors.companyNip
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              Podaj NIP firmy
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" sm="6">
                          <Form.Group as={Col} controlId="companyStreet">
                            <Form.Label>Ulica i nr</Form.Label>
                            <Form.Control
                              type="text"
                              name="companyStreet"
                              value={values.companyStreet}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isValid={
                                touched.companyStreet && !errors.companyStreet
                              }
                              isInvalid={
                                touched.companyStreet && errors.companyStreet
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              Podaj ulicę i nr
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col xs="12" sm="6">
                          <Form.Group as={Col} controlId="companyPostalCode">
                            <Form.Label>Kod pocztowy</Form.Label>
                            <Form.Control
                              type="text"
                              name="companyPostalCode"
                              value={values.companyPostalCode}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isValid={
                                touched.companyPostalCode &&
                                !errors.companyPostalCode
                              }
                              isInvalid={
                                touched.companyPostalCode &&
                                errors.companyPostalCode
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              Podaj kod pocztowy
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="12" sm="6">
                          <Form.Group as={Col} controlId="companyCity">
                            <Form.Label>Miasto</Form.Label>
                            <Form.Control
                              type="text"
                              name="companyCity"
                              value={values.companyCity}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isValid={
                                touched.companyCity && !errors.companyCity
                              }
                              isInvalid={
                                touched.companyCity && errors.companyCity
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              Podaj miasto
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                )}

                <Row>
                  <Col>
                    <Button
                      type="submit"
                      disabled={
                        isSubmitting ||
                        (priceCategory0.personLimited &&
                          priceCategory1.personLimited &&
                          priceCategory2.personLimited)
                      }
                    >
                      Zapisz się
                      {payment && <span> i zapłać online</span>}
                      {isSubmitting && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {payment && (
                      <div>
                        <div className="security">
                          * Bezpieczeństwo płatności zapewnia Tpay
                        </div>
                        <img
                          id="tpay-image"
                          src="https://tpay.com/img/banners/tpay_com_zaufane_platnosci_bar.svg"
                          alt="Tpay - zaufane płatności"
                          title="Tpay - zaufane płatności"
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    );
  }
}

export default RegistrationForm;
