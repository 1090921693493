import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Card from "react-bootstrap/Card";

import fmf from "../assets/fmf.png";
import samsung from "../assets/samsung.png";
import ge from "../assets/ge.png";

import thermofisher from "../assets/thermofisher.png";
import hvd from "../assets/hvd.png";
import polpharma from "../assets/polpharma.png";
import exeltis from "../assets/exeltis.png";
import nipt from "../assets/nipt.png";
import gr from "../assets/gr.png";
import pbkm from "../assets/pbkm.png";
import nf from "../assets/nf.png";

class Partners extends Component {
  render() {
    return (
      <div className="partners">
        <Container>
          <h2>Nasi Partnerzy</h2>
          <Row className="justify-content-md-center justify-content-sm-center">
            {/* <Col className="item" sm="auto">
              <Card>
                <Card.Body>
                  <Image src={fmf}></Image>
                </Card.Body>
              </Card>
            </Col> */}
            <Col className="item" sm="auto">
              <Card>
                <Card.Body>
                  <Image src={polpharma}></Image>
                </Card.Body>
              </Card>
            </Col>
            <Col className="item" sm="auto">
              <Card>
                <Card.Body>
                  <Image src={ge}></Image>
                </Card.Body>
              </Card>
            </Col>
            <Col className="item" sm="auto">
              <Card>
                <Card.Body>
                  <Image src={thermofisher}></Image>
                </Card.Body>
              </Card>
            </Col>
            {/* <Col className="item" sm="auto">
              <Card>
                <Card.Body>
                  <Image src={hvd}></Image>
                </Card.Body>
              </Card>
            </Col> */}
            <Col className="item" sm="auto">
              <Card>
                <Card.Body>
                  <Image src={exeltis}></Image>
                </Card.Body>
              </Card>
            </Col>
            <Col className="item" sm="auto">
              <Card>
                <Card.Body>
                  <Image src={nipt}></Image>
                </Card.Body>
              </Card>
            </Col>
            <Col className="item" sm="auto">
              <Card>
                <Card.Body>
                  <Image src={gr}></Image>
                </Card.Body>
              </Card>
            </Col>
            <Col className="item" sm="auto">
              <Card>
                <Card.Body>
                  <Image src={pbkm}></Image>
                </Card.Body>
              </Card>
            </Col>
            <Col className="item" sm="auto">
              <Card>
                <Card.Body>
                  <Image src={nf}></Image>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Partners;
